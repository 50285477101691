<template>
  <div class="submit-button"
       :class="{ 'active': submitAvailable, 'submitting': isSubmitting, 'submitted': hasSubmitted }"
       @click="handleSubmitButtonClick">
    <template v-if="isSubmitting">
      요청중...
    </template>
    <template v-else>
      {{ hasSubmitted ? '사전예약 완료' : '사전예약하기' }}
    </template>
  </div>
</template>

<script>
import {finish_sms_auth} from '@/landing_page/helper/lp_api.js';

export default {
  name: "SubmitButton",
  props: {
    submitAvailable: Boolean,
    inputValues: Object,
    referralCode: String,
    sampleData: Object,
  },
  emits: ['submitted', 'wrongNumber'],
  data() {
    return {
      isSubmitting: false,
      hasSubmitted: false,
      myReferralCode: '',
    };
  },
  methods: {
    async handleSubmitButtonClick() {
      if (!this.submitAvailable || this.isSubmitting || this.hasSubmitted) return;
      this.isSubmitting = true;

      const phoneNumber = this.inputValues['전화번호'];
      const verificationCode = this.inputValues['인증번호'];
      const nickname = this.inputValues['닉네임'];

      let res = await finish_sms_auth(this.sampleData.basic_info.title, nickname, phoneNumber, verificationCode, this.referralCode);
      if (res.result === 'success') {

        this.hasSubmitted = true;
        this.isSubmitting = false;
        this.myReferralCode = res.data.referral_code;
        this.$emit('submitted', '짜란- 🎉 Ticky Talk 사전예약 완료!');
        if (typeof window.fbq === 'function') {
          window.fbq('track', 'SubmitApplication');
        } else {
          console.error('Facebook Pixel not loaded');
        }

        setTimeout(() => {
          const newUrl = `/${this.sampleData.basic_info.title}/share?phone_number=${encodeURIComponent(phoneNumber)}&referral_code=${encodeURIComponent(this.myReferralCode)}`;
          this.$router.push(newUrl);
        }, 3000);

      } else if (res.message === '인증번호가 틀렸습니다') {
        this.hasSubmitted = false;
        this.isSubmitting = false;
        this.$emit('wrongNumber');

      }
    }
  },
}
</script>

<style scoped>
.submit-button{
  display: flex;
  width: 100%;
  height: 64px;
  margin: 38px auto;
  font-size: 18px;
  font-weight: 700;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 100px;
  background: #C6C6C6;
}

.submit-button.active {
  background: #FF7224; /* 활성화 상태일 때의 배경색 */
  color: #000000;
}

.submit-button.submitted {
  background: #C6C6C6; /* 예시 색상 */
  color: #616161; /* 예시 텍스트 색상 */
}


.submit-button.submitting {
  background: #C6C6C6; /* 예시 색상 */
  color: #616161; /* 예시 텍스트 색상 */
}

</style>