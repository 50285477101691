<template>
  <div class="modal" :class="{ 'is-active': isActive }" v-if="isActive">
    <div class="modal-background" @click="backgroundClick"></div>
    <div class="modal-content">
      <div class="modal-close" aria-label="close" @click="closePopupCallback">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" fill="#FF7224"/>
        </svg>
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "BasicPopup",
  props: {
    isActive: {
      type: Boolean,
      required: true,
    },
    closePopupCallback: {
      type: Function,
      required: true,
    },
    disableOutsideClick: { // 바깥 클릭 이벤트를 비활성화할지 결정하는 속성
      type: Boolean,
      default: false,
    }
  },
  methods: {
    backgroundClick() {
      if (!this.disableOutsideClick) {
        this.closePopupCallback();
      }
    }
  }
}
</script>

<style>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  overflow: auto;
  z-index: 9999 !important;
}

.modal.is-active {
  display: flex;
}

.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
}

.modal-content {
  position: relative;
  width:88.8%;
  height: 90vh;
  margin: auto;
  max-width: 480px;
  max-height: 80vh;
  overflow: visible;
  transform: translate(0,0);
  background-color: #ffffff;
  border-radius: 13px;
}

.modal-close {
  position: absolute;
  right: 10px;
  top: 25px;
  background: transparent;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: #bbbbbb;
  border-radius: 50%;
  padding: 5px 10px;
  line-height: 1;
  outline: none;
}


h1 {
  font-size: 18px;
  font-weight: 700;
  margin: 0 0;
}

h2 {
  font-size: 1.375rem;
  font-weight: 700;
  margin: 0 0;
}

p {
  font-size: 1rem;
  font-weight: 400;
  margin: 4px 0;
  color: #808080;
}

</style>