<template>
  <div>
    <h1>404 Not Found</h1>
    <p>요청하신 페이지를 찾을 수 없습니다.</p>
  </div>
</template>

<script>
export default {
  name: "NotFoundPage"
}
</script>

<style scoped>

</style>