<template>
  <div class="verification">
    <div v-if="isVerificationActive" class="verification-time">
      {{ formattedVerificationTime }}
    </div>
    <div :class="verificationButtonClass" @click="handleButtonClick">
      {{ buttonText }}
    </div>
  </div>

  <div class="input-item" id="verification-code">
    <div v-if="showClearButton"
         class="clear-button"
         @click="clearNumber">
      <svg width="20" height="20" viewBox="0 0 20 20" fill="#CAC4D0" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10 0C4.47 0 0 4.47 0 10C0 15.53 4.47 20 10 20C15.53 20 20 15.53 20 10C20 4.47 15.53 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18ZM10 8.59L13.59 5L15 6.41L11.41 10L15 13.59L13.59 15L10 11.41L6.41 15L5 13.59L8.59 10L5 6.41L6.41 5L10 8.59Z" fill="#CAC4D0"/>
      </svg>
    </div>
    <div v-if="isNumberWrong"
         class="warning-message">
      인증번호를 다시 확인해주세요
    </div>

    <label for="verificationCode" :class="labelClass">인증 번호</label>
    <input type="tel"
           id="verification-input"
           maxlength="5"
           v-model="verificationCode"
           :class="verificationInputClass"
           placeholder="5자리 숫자"
           @input="handleInput"
           ref="verificationInput">
  </div>
</template>

<script>
import { startSmsAuth, checkUserInfo } from '@/landing_page/helper/lp_api.js';

export default {
  name: "PhoneVerificationModule",
  data() {
    return {
      verificationCode: '',
      isVerificationActive: false,
      showClearButton: false,
      hasRequested: false,
      verificationTimer: null,
      verificationTimeLeft: null,
      isRequesting: false,
    }
  },
  props: {
    verificationActive: Boolean,
    isNumberWrong: Boolean,
    inputValues: Object,
    sampleData: Object
  },
  emits: ['input', 'submitted'],
  computed: {
    formattedVerificationTime() {
      const minutes = Math.floor(this.verificationTimeLeft / 60);
      const seconds = this.verificationTimeLeft % 60;
      return `${minutes}분 ${seconds.toString().padStart(2, '0')}초 남음`;
    },
    verificationButtonClass() {
      if (this.hasRequested) return 'verification-button-inactive';
      if (this.isRequesting) return 'verification-button-inactive';
      return this.verificationActive ? 'verification-button-active' : 'verification-button-inactive';
    },
    verificationInputClass() {
      return this.hasRequested ? 'verification-input-active' : 'verification-input';
    },
    buttonText() {
      if (this.isRequesting) {
        return '요청 중...'; // API 호출 중
      } else if (this.hasRequested) {
        return '요청 완료';
      } else {
        return '인증번호 받기'; // 기본 텍스트
      }
    },
    labelClass() {
      return this.hasRequested ? 'input-label-active' : 'input-label';
    },
  },
  methods: {
    handleInput() {
      this.showClearButton = this.verificationCode.length > 0;
      this.$emit('input', { label: '인증번호', value: this.verificationCode });
    },
    async handleButtonClick() {
      if (!this.verificationActive || this.isRequesting || this.hasRequested ) return;
      this.isRequesting = true;

      const phoneNumber = this.inputValues['전화번호'];

      let userRes = await checkUserInfo(this.sampleData.basic_info.title, phoneNumber);
      if (userRes.result === 'success') {
        this.$emit('submitted', '이미 신청을 완료하셨어요!');
        this.isRequesting = false;
      }

      else {
        let smsRes = await startSmsAuth(this.sampleData.basic_info.title, phoneNumber);
        if(smsRes.result === 'success'){
          this.startVerificationTimer();
          this.hasRequested = true;
        } else {
          this.hasRequested = false;
        }
        this.isRequesting = false; // API 호출 완료
      }
    },
    startVerificationTimer() {
      if (this.verificationTimer) {
        this.clearVerificationTimer();
      }

      this.isVerificationActive = true;
      this.verificationTimeLeft = 60;
      this.verificationTimer = setInterval(() => {
        if (this.verificationTimeLeft > 0) {
          this.verificationTimeLeft--;
        } else {
          this.clearVerificationTimer();
          this.hasRequested = false;
        }
      }, 1000);
    },
    clearVerificationTimer() {
      clearInterval(this.verificationTimer);
      this.verificationTimer = null; // 타이머 초기화
      this.isVerificationActive = false;
      this.verificationTimeLeft = null;
      this.hasRequested = false;
    },
    clearNumber() {
      this.verificationCode = ''
      this.showClearButton = false;
    },
  },
  watch: {
    hasRequested(newVal) {
      if (newVal) {
        this.$nextTick(() => {
          this.$refs.verificationInput.focus();
        });
      }
    },
  },
}
</script>


<style scoped>
.verification {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  margin-top: 12px;
}

.verification-time {
  color: #9C9C9C;
  font-size: 14px;
  margin-right: 6px;
}

/* 스타일링: 인증 버튼 */
.verification-button-active {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FF7224; /* 활성화된 상태의 배경색 */
  color: white;
  cursor: pointer;
  border-radius: 100px;
  padding: 8px 12px;
  font-size: 14px;
  font-weight: 800;
}

.verification-button-inactive {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #969696; /* 비활성화된 상태의 배경색 */
  color: #616161;
  cursor: default;
  border-radius: 100px;
  padding: 8px 12px;
  font-size: 14px;
  font-weight: 800;
}

/* 스타일링: 입력 필드 */
.verification-input {
  height: 56px;
  padding: 4px 0px 4px 16px;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
  border: 2px solid #969696; /* 기본 테두리 색상 */
  border-radius: 4px;
  outline: none;
  color: white;
  font-size: 18px;
  background-color: black;
}

.verification-input-active {
  height: 56px;
  padding: 4px 0px 4px 16px;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
  border: 2px solid #FF7224; /* 활성화된 상태의 테두리 색상 */
  border-radius: 4px;
  outline: none;
  color: white;
  font-size: 18px;
  background-color: black;
}

.input-item {
  height: 60px;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 26px;
  margin-bottom: 12px;
}

.input-label {
  position: absolute;
  top: -6px;
  left: 12px;
  padding: 0 4px;
  background-color: black;
  color: #9C9C9C;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 4px;
}

.input-label-active {
  position: absolute;
  top: -6px;
  left: 12px;
  padding: 0 4px;
  background-color: black;
  color: #FF7224; /* 활성화 상태의 색상 */
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 4px;
}
.clear-button {
  position: absolute;
  right: 14px;
  top: 50%;
  transform: translateY(-50%);
}

.warning-message{
  color: #F64D4D;
  position: absolute;
  left: 0;
  bottom: -28px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
</style>
