import { createApp } from 'vue'
import { createVueKakaoSdk } from 'vue3-kakao-sdk'

import App from './App.vue'
import { createRouter, createWebHistory } from "vue-router";
import MainPage from "@/landing_page/single_landing_page/MainPage.vue";
import NotFoundPage from "@/landing_page/single_landing_page/NotFoundPage";
import SharePage from "@/landing_page/share_page/SharePage";

const routes = [
    { path: "/", component: MainPage },
    {
        path: '/:project/:version/:abTest',
        name: 'LandingPage',
        component: MainPage,
        props: true
    },
    { path: '/404', name: 'NotFound', component: NotFoundPage },
    {
        path: '/:project/share',
        name: 'SharePage',
        component: SharePage,
        props: true
    }

];

const router = createRouter({
    history: createWebHistory(),
    routes: routes,
})
export default router


const app = createApp(App);


app.use(router)
app.use(createVueKakaoSdk('1ae560d93f52a65d58aaddadee0e9819'))


app.mount('#app')