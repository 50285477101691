<template>
  <div class="input-item" v-if="type !== 'verification'">
    <label :for="label" class="input-label">{{ label }}</label>
    <input :type="type"
           :maxLength="inputMaxLength"
           :placeholder="placeholder"
           :value="formattedInput"
           @input="handleInput"
           class="nickname-input">


    <div v-if="showClearButton"
         class="clear-button"
         @click="clearPhoneNumber">
      <svg width="20" height="20" viewBox="0 0 20 20" fill="#CAC4D0" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10 0C4.47 0 0 4.47 0 10C0 15.53 4.47 20 10 20C15.53 20 20 15.53 20 10C20 4.47 15.53 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18ZM10 8.59L13.59 5L15 6.41L11.41 10L15 13.59L13.59 15L10 11.41L6.41 15L5 13.59L8.59 10L5 6.41L6.41 5L10 8.59Z" fill="#CAC4D0"/>
      </svg>
    </div>

  </div>
</template>

<script>
export default {
  name: "BasicInputModule",
  props: {
    type: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    isActive:{
      type: Boolean
    }
  },
  emits: ['input', 'update:fulfilled'],
  data() {
    return {
      inputData: '',
      isFulfilled: false,
      showClearButton: false,
    };
  },
  computed: {
    formattedInput() {
      if (this.type === 'tel') {
        return this.inputData.replace(/(\d{3})(\d{1,4})(\d{0,4})/, (match, p1, p2, p3) => {
          if (p3) {
            return `${p1} ${p2} ${p3}`;
          } else if (p2) {
            return `${p1} ${p2}`;
          } else {
            return p1;
          }
        });
      }
      return this.inputData;
    },
    inputMaxLength() {
      switch (this.type) {
        case 'text':
          return 6;
        case 'tel':
          return 13;
        case 'verification':
          return 5;
        default:
          return 12; // 기본적인 최대 길이
      }
    },
  },
  methods: {
    handleInput(event) {
      const value = event.target.value;
      if (this.type === 'tel') {
        this.inputData = value.replace(/\D/g, '').slice(0, 11);
        this.$emit('input', { label: this.label, value: this.inputData });

      } else {
        this.inputData = value;
        this.$emit('input', { label: this.label, value: this.inputData });
      }
      this.checkFulfillment();
    },
    formatPhoneNumber(value) {
      const numbers = value.replace(/\D/g, '').slice(0, 13); // 숫자만 추출하고 최대 11자리로 제한
      let formatted = '';

      if (numbers.length > 7) {
        formatted = `${numbers.slice(0, 3)} ${numbers.slice(3, 7)} ${numbers.slice(7)}`;
      } else if (numbers.length > 3) {
        formatted = `${numbers.slice(0, 3)} ${numbers.slice(3)}`;
      } else {
        formatted = numbers;
      }

      return formatted;
    },
    checkFulfillment() {
      if (this.type === 'text') {
        this.isFulfilled = this.inputData.length > 1;
        this.showClearButton = this.inputData.length > 0;
      } else if (this.type === 'tel') {
        this.showClearButton = this.inputData.length > 0;
        this.isFulfilled = this.inputData.length === 11;
      }
      this.$emit('update:fulfilled', this.isFulfilled);
    },
    toggleClearButton() {
      this.showClearButton = this.phoneNumberRaw.length > 0;
    },
    clearPhoneNumber() {
      this.inputData = ''
      this.showClearButton = false;
    },
  }
}
</script>

<style scoped>

.input-item{
  height: 60px;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 38px;
}

.input-fields{
  width: 100%;
  margin-top: 32px;
}

.input-label {
  position: absolute;
  top: -6px;
  left: 12px;
  padding: 0 4px;
  background-color: black;
  color: #FF7224;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 4px;
}

.input-label-inactive {
  position: absolute;
  top: -6px;
  left: 12px;
  padding: 0 4px;
  background-color: black;
  color: #969696;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 4px;
}

.nickname-input {
  height: 56px;
  padding: 4px 0px 4px 16px;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
  border: 2px solid #FF7224;
  border-radius: 4px;
  outline: none;
  color: white;
  font-size: 18px;
  background-color: black;
}

.nickname-input input::placeholder {
  color: #969696;
  font-size: 18px;
}

.nickname-input:focus {
  outline: 2px solid #FF7224;
}

.nickname-input input::placeholder {
  color: #969696;
  font-size: 18px;
}

.nickname-input:focus {
  outline: 2px solid #FF7224;
}

.clear-button {
  position: absolute;
  right: 14px;
  top: 50%;
  transform: translateY(-50%);
}
</style>