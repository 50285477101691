<template>
  <div class="checkbox-item">
    <div :class="['checkbox-circle', isChecked ? 'checked' : '']" @click="toggleCheckbox">
    <div class="checkbox-check">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 20 20" :fill="isChecked ? '#000000' : '#7B7B7B'">
        <path d="M7.49989 13.4749L4.02489 9.99987L2.84155 11.1749L7.49989 15.8332L17.4999 5.8332L16.3249 4.6582L7.49989 13.4749Z"/>
      </svg>
    </div>
  </div>
  <div class="checkbox-text">
    {{ label }}
  </div>
</div>
</template>

<script>
export default {
  name: "BasicCheckBoxModule.vue",
  components: {},
  props: {
    label: String,
    type: String,
    isRequired: Boolean
  },
  emits: ['update:checked'],
  data() {
    return {
      isChecked: true,
    };
  },
  methods: {
    toggleCheckbox() {
      this.isChecked = !this.isChecked;
      this.$emit('update:checked', { type: this.type, value: this.isChecked });
    },
  },
}
</script>

<style scoped>
.checkbox-item{
  height: 30px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-top: 30px;
}

.checkbox-circle{
  display: flex;
  width: 30px;
  height: 30px;
  padding: 6px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 100px;
  border: 1.5px solid #898989;
}

.checkbox-text{
  color: #FF7224;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 16px */
  text-decoration-line: underline;
  margin-left: 16px;
}

.checkbox-check{
  transform: translateY(2px);
}

.checkbox-circle.checked {
  border: 1.5px solid #FF7224; /* 체크 상태일 때의 보더 색상 */
  background-color: #FF7224;
}

.checkbox-check.checked {
  /* 체크 상태일 때의 SVG 스타일 (필요한 경우) */
}

</style>