<template>
  <button class="fixed-button" @click="scrollToInputSection" :style="buttonStyle">
    {{ dataset.cta_fields.cta_text }}&nbsp;

    <svg width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2 2L8.77419 9L2 16" :stroke="svgIconColor" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

  </button>
</template>

<script>
import SampleDataSet from "@/landing_page/single_landing_page/SampleDataSet.json";

export default {
  name: "BottomButtonModule",
  data() {
    return{
      dataset: SampleDataSet,
    }
  },
  emits: ['scrollToInput'],
  computed: {
    buttonStyle() {
      return {
        backgroundColor: this.dataset.cta_fields.cta_background_color,
        color: this.dataset.cta_fields.cta_text_color,
      };
    },
    svgIconColor() {
      return this.dataset.cta_fields.cta_text_color;
    },
  },
  methods: {
    scrollToInputSection() {
      this.$emit('scrollToInput');
    },
  }
}
</script>

<style scoped>

.fixed-button {
  position: fixed;
  bottom: 0;
  left: 50%; /* 화면 중앙 정렬 */
  transform: translateX(-50%); /* 화면 중앙 정렬 보정 */
  width: 100%; /* PC 사이즈 너비 */
  height: 60px; /* 높이 */
  display: flex;
  align-items: center; /* 세로 중앙 정렬 */
  justify-content: center; /* 가로 중앙 정렬 */
  font-size: 18px;
  box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.2);
  border: none;
  font-weight: 900;
}
@media (max-width: 600px) {
  .fixed-button {
    width: 100%; /* 모바일 환경에서 전체 너비 */
    bottom: 0;
    left: 0; /* 모바일 환경에서 좌측 정렬 */
    transform: none; /* 모바일 환경에서 중앙 정렬 보정 제거 */
    font-size: 18px;
    border: none;
    font-weight: 900;
  }
}
</style>