<template>
  <BasicPopup :isActive="isActive" :closePopupCallback="closePopupCallback">
    <div class="title">
      <h1>랭킹별 할인</h1>
    </div>
    <div class="image-field">
      <img src="https://firebasestorage.googleapis.com/v0/b/meta-play-project.appspot.com/o/tikitalk-lp-231223%2Fsalerate-popup.jpg?alt=media&token=1a88d412-a1c4-4e7b-8869-d177b6fa1894">
    </div>
  </BasicPopup>
</template>

<script>
import BasicPopup from "@/landing_page/components/popup/BasicPopup";
export default {
  name: "ShareInfoPopup",
  components: {BasicPopup},
  props: {
    isActive: {
      type: Boolean,
      required: true,
    },
    closePopupCallback: {
      type: Function,
      required: true,
    },
  }
}
</script>

<style scoped>
.title{
  text-align: left;
  color: #000000;
  margin: 30px 20px 0 20px;
}

.image-field{
  position: relative;
  display:flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 90%;
  height: 90%;
  margin: 10px auto;
}

.image-field img{
  position: relative;
  max-width: 100%;
  max-height: 90%;
  border-radius: 10px;
  object-fit: cover;
  object-position: center;
}

</style>