export const HOST = "https://api.meta-play.co.kr";

export async function startSmsAuth(service, phoneNumber) {
    const response = await defaultApiCall('/pre_users/' + service + '/start_sms_auth', {
        phone_number: phoneNumber,
    });
    return response;
}

export async function finish_sms_auth(service, userName, phoneNumber, certNumber, referralCode) {
    const response = await defaultApiCall('/pre_users/' + service + '/finish_sms_auth', {
        phone_number: phoneNumber,
        user_name: userName,
        cert_number: certNumber,
        referral_code: referralCode
    });
    return response;
}

export async function checkUserInfo(service, phoneNumber) {
    const response = await defaultApiCall('/pre_users/' + service + '/user_info', {
        phone_number: phoneNumber,
    });
    return response;
}


async function defaultApiCall(url, formData) {
    const MAX_RETRY = 2;
    let attempts = 0;

    while (attempts <= MAX_RETRY) {
        try {
            const response = await fetch(`${HOST}${url}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });

            if (!response.ok) {
                console.log("API 호출에 실패했습니다.");
                throw new Error('API 호출에 실패했습니다.'); // 실패 시 에러 던지기
            }

            const jsonData = await response.json();
            if (jsonData.result != "success") {
                console.log(jsonData.message);
            }
            console.log(jsonData);
            return jsonData;
        }
        catch (error) {
            attempts++;
            await sleep(500); // 여기에서 500ms의 텀을 둡니다.
            console.error(`API 통신 중 오류 발생 (시도 ${attempts}):`, error);

            if (attempts > MAX_RETRY) {
                console.error("API 호출에 실패했습니다.");
                return { result: 'failed' };
            }
        }
    }
}

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}